import { BasicEvent } from "@h4x/common";
import { normalizeCode } from "./HewSyncUtils";
import { HewSync, type HewSyncTypeConfig } from "./HewSync";
import { type Writable, writable } from "svelte/store";
import { debug } from "console";

interface BaseSubscriptionEvent {
	readonly $event: string;
}

interface CreatedEvent extends BaseSubscriptionEvent {
	readonly $event: "Created";
	readonly type: string;
}

interface KeyUpdatedEvent extends BaseSubscriptionEvent {
	readonly $event: "KeyUpdated";
	readonly old: any;
	readonly updated: any;
}

interface UpdatedEvent extends BaseSubscriptionEvent {
	readonly $event: "Updated";
	readonly type: string;
}

interface RemovedEvent extends BaseSubscriptionEvent {
	readonly $event: "Removed";
	readonly type: string;
}

export type SubscriptionEvent = CreatedEvent | UpdatedEvent | KeyUpdatedEvent | RemovedEvent;

interface BaseSessionResponse {
	readonly message: string | null;
}

interface DataSessionResponse extends BaseSessionResponse {
	readonly data: {
		url: string;
		accountID: string;
	};
	readonly error: null;
}

interface InvalidTokenTypeSessionResponse extends BaseSessionResponse {
	readonly data: null;
	readonly error: "InvalidTokenType";
}
interface FailedToCreateAccountSessionResponse extends BaseSessionResponse {
	readonly data: null;
	readonly error: {
		readonly FailedToCreateAccount: string;
	};
}

interface FailedToUpdateAccountSessionResponse extends BaseSessionResponse {
	readonly data: null;
	readonly error: {
		readonly FailedToUpdateAccount: string;
	};
}

interface CorruptAccountSessionResponse extends BaseSessionResponse {
	readonly data: null;
	readonly error: {
		readonly CorruptAccount: {
			readonly reason: string;
		};
	};
}

export type SessionResponse =
	| DataSessionResponse
	| InvalidTokenTypeSessionResponse
	| FailedToCreateAccountSessionResponse
	| FailedToUpdateAccountSessionResponse
	| CorruptAccountSessionResponse;

export type ErrorSessionResponse =
	| InvalidTokenTypeSessionResponse
	| FailedToCreateAccountSessionResponse
	| FailedToUpdateAccountSessionResponse
	| CorruptAccountSessionResponse
	| { error: "Connection Error" };

export type HewSyncConnectionState =
	| "connected"
	| "disconnected"
	| "connecting"
	| "connection-error"
	| "error"
	| "not-authenticated";

export const HewSyncConnectedState: HewSyncConnectionState[] = ["connected"];
export const HewSyncWarningState: HewSyncConnectionState[] = ["connecting", "error"];
export const HewSyncDisconnectedState: HewSyncConnectionState[] = [
	"disconnected",
	"not-authenticated",
	"connection-error"
];

export class HewSyncSocket {
	private auth?: string;
	private id_token: string;
	private accountID?: string;

	private disabled = false;
	private baseUrl: string;

	public connectionState = writable<HewSyncConnectionState>("connecting");
	public error = writable<ErrorSessionResponse | null>(null);

	constructor() {}

	public disable() {
		this.disabled = true;
	}

	private ws: WebSocket | undefined;

	public async connect(auth?: string, connectionUrl: string = "", id_token?: string) {
		this.baseUrl = connectionUrl;

		if (auth && this.auth !== auth) {
			this.auth = auth;
			this.ws?.close();
			this.ws = undefined;
		}

		// Should be moved
		if (id_token && this.id_token !== id_token) {
			this.id_token = id_token;
		}

		if (this.ws) {
			return this.accountID;
		}

		if (this.auth === undefined) {
			this.connectionState.set("not-authenticated");
			throw new Error("Not authenticated");
		}

		this.ws = new WebSocket(`${this.baseUrl}`);
		this.ws.addEventListener("open", (event) => {
			this.connectionState.set("connected");
			HewSync.onConnectionUpdate.execute();
			this.onOpen();
		});

		this.ws.addEventListener("message", (event) => {
			try {
				this.onMessage(JSON.parse(event.data as string) as { type: string; [id: string]: any });
			} catch (e) {
				console.error(e);
			}
		});

		this.ws.addEventListener("close", () => {
			this.error.set({ error: "Connection Error" });
			this.connectionState.set("disconnected");
			HewSync.onConnectionUpdate.execute();
		});

		this.connectionState.set("connected");

		HewSync.onConnectionUpdate.execute();

		return this.accountID;
	}

	private responseMap = new Map<
		string,
		{
			resolve: (data: any) => void;
			reject: (error: any) => void;
		}
	>();
	public async request<T>(data: { type: string; [id: string]: any }) {
		await this.authorized;

		/*
			pub(crate) id:      String,
			pub(crate) org:     Option<String>,
			// #[serde(flatten)]
			pub(crate) request: HewSyncRequest,
		*/
		let id = crypto.randomUUID();
		this.send({
			type: "HewSync",
			payload: {
				id,
				request: data
			}
		});

		return new Promise<T>((resolve, reject) => {
			this.responseMap.set(id, { resolve, reject });
		});
	}

	public proxy(sender: (data: string) => void, receiver: BasicEvent<(data: string) => void>) {
		this.ws = {
			send: (data: string) => {
				sender(data);
			},
			close: () => {}
		} as any as WebSocket;

		receiver.addCallback((data) => {
			this.onMessage(JSON.parse(data) as { type: string; [id: string]: any });
		});

		this.authorized = Promise.resolve(true);
	}

	private onOpen() {
		console.debug("[HewSyncSocket] Successfully connected");
		// this.send({ type: "connection_init", payload: {} });
		// {"type":"connection_ack","payload":{"connectionTimeoutMs":300000}}
		this.authorizedResolve?.(true);
		this.authorized = true;
		this.connectionState.set("connected");
	}

	private authorizedResolve: ((result: true) => void) | undefined = undefined;
	private authorized: Promise<true> | true | undefined = new Promise((resolve) => {
		this.authorizedResolve = resolve;
	});
	private onMessage(data: any) {
		if (data.type === "data") {
		} else if (data.type === "HewSync") {
			let response = this.responseMap.get(data.id);
			if (response) {
				response.resolve(data);
				this.responseMap.delete(data.id);
			}
		} else if (data.type === "HewSyncEvent") {
			console.log("[HewSyncSocket] Event", data.data);
			let event = data.data as SubscriptionEvent;

			if (event.$event === "Created") {
				let subscriptions = this.subscriptions.get(event.type);
				if (subscriptions) {
					subscriptions.callback.execute(event);
				}
			} else if (event.$event === "Updated") {
				let subscriptions = this.subscriptions.get(event.type);
				if (subscriptions) {
					subscriptions.callback.execute(event);
				}
			} else if (event.$event === "KeyUpdated") {
				let subscriptions = this.subscriptions.get(event.updated.type);
				if (subscriptions) {
					subscriptions.callback.execute(event);
				}
			} else if (event.$event === "Removed") {
				console.log("[HewSyncSocket] Removed", data.data);
				let subscriptions = this.subscriptions.get(event.type);
				if (subscriptions) {
					console.log("[HewSyncSocket] subscriptions", data.data);
					subscriptions.callback.execute(event);
				}
			}
		} else if (data.type === "Error") {
			console.error("[HewSyncSocket] Error", ...data.payload.errors);
		}
	}

	private subscriptions = new Map<
		string,
		{
			callback: BasicEvent<(data: any) => void>;
		}
	>();
	public async subscribe<T>(name: string, callback: (data: T) => void) {
		await HewSync.authPromise;
		if (this.disabled) {
			this.connectionState.set("disconnected");
			return;
		}
		if (this.authorized === undefined) {
			throw new Error("Not connected");
		} else if (this.authorized !== true) {
			await this.authorized;
		}

		let subscriptions = this.subscriptions.get(name);
		if (!subscriptions) {
			console.log("Creating subscription", name);
			subscriptions = {
				callback: new BasicEvent()
			};
			this.subscriptions.set(name, subscriptions);
		}

		subscriptions.callback.addCallback(callback);
	}

	private send(data: any) {
		if (!this.ws) {
			throw new Error("Not connected");
		}

		if (!this.isConnected) {
			this.connectionState.set("disconnected");
		}

		this.ws.send(JSON.stringify(data));
	}

	public get isConnected() {
		return this.ws !== undefined && this.ws.readyState !== WebSocket.CLOSED;
	}
}
